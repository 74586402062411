import React, { useState, createContext, useEffect } from 'react';
import log from 'helper/log';
import permission from 'helper/permission';
import debug from 'helper/debug';

const defaults = {
    user: {
        email: '',
        accountNumber: '',
        isDefault: true,
        balance: 0
    },
    setUser: () => {},
    switching: false,
    setSwitching: () => {},
}
const UserContext = createContext(defaults);

export const UserContextProvider = (props) => {
    const [user, setUser] = useState(defaults.user);
    const [balance, setBalance] = useState(defaults.user.balance);
    const [switching, setSwitching] = useState(defaults.switching);

    useEffect(() => {
        if (!user.isDefault) {
            log.setCustomer(user);
            permission.set(user.permissions);
            setBalance(user.balance);
            debug.table({
                codasId: user.accountNumber,
                email: user.email,
                type: user.type,
                canMakePaymentForOrders: user.permissions.actions.canMakePaymentForOrders,
                isOnStop: user.permissions.actions.isOnStop,
                isFinishDated: user.permissions.actions.isFinishDated,
                canPlaceOrders: user.permissions.actions.canPlaceOrders[user.type],
                isChild: user.permissions.actions.isChild,
                isCashOnDelivery: user.permissions.actions.isCashOnDelivery,
                isDirectDebitType: user.permissions.actions.isDirectDebitType,
                canUseCredit: user.permissions.actions.canUseCredit
            });
        }
    }, [user]);

    return <UserContext.Provider
        value={ {
            user: user,
            setUser: setUser,
            balance: balance,
            setBalance: setBalance,
            switching: switching,
            setSwitching: setSwitching,
        } }>{ props.children }</UserContext.Provider>;
}

export default UserContext;