import React, { useState, createContext, useEffect, useReducer, useContext } from 'react';
import { api } from 'helper/api/client';
import Modal from 'portal/Modal';
import { modalInitialState, modalReducer } from 'helper/modalReducer';
import BasketMessage from 'components/ui/BasketMessage';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import permission from 'helper/permission';

const defaults = {
    orders: null,
    setOrders: () => {},
}
const OrderContext = createContext(defaults);

export const OrderContextProvider = (props) => {
    const { user, switching } = useContext(UserContext);
    const { authenticated } = useContext(AuthContext);

    const [orders, setOrders] = useState(defaults.orders);
    const [basketMessage, setBasketMessage] = useState(null);
    const [modal, dispatchModal] = useReducer(modalReducer, modalInitialState);


    useEffect(() => {
        setOrders(null);
        setBasketMessage(null);
        dispatchModal({ type: 'close' });
    }, [authenticated, switching]);
    useEffect(() => {
        if (!user.isDefault && (orders === null || (orders && orders.current && orders.current.customerId !== user.id))) {
            setBasketMessage(null);
            permission.set(user.permissions);
            if (permission.check('isCylinder')) {
                api.get('cylinder/orders').then(response => {
                    if (api.success(response)) {
                        setOrders(response.data.response);
                        api.setCache(response, 1);
                    } else {
                        setOrders(false);
                    }
                });
            }
        }
    }, [user, orders]);
    useEffect(() => {
        if (basketMessage !== null) {
            dispatchModal({
                type: 'open',
                title: 'Basket updated',
                yesOption: 'View basket',
                yesAction: () => {
                    if (basketMessage.history) {
                        basketMessage.history.push('/basket');
                        dispatchModal({ type: 'close' });
                    }
                },
                content: <BasketMessage basketMessage={ basketMessage } />
            });
        }
    }, [orders, basketMessage]);

    return <OrderContext.Provider
        value={ {
            orders: orders,
            setOrders: setOrders,
            setBasketMessage: setBasketMessage
        } }>
        { props.children }
        { <Modal { ...modal } dispatchModal={ dispatchModal } noOption="Close">{ modal.content }</Modal> }
    </OrderContext.Provider>;
}

export default OrderContext;