import React, { useRef, useState, useEffect } from 'react';
import { getAllowedAttributes } from 'helper/form/fields';
import { useDetectOutsideClick } from 'helper/useDetectOutsideClick';
import Button from 'components/ui/Button';

const ProductFilters = (props) => {
    const allowedAttributes = [
        'name',
        'value',
        'disabled',
        ...props.allowedAttributes
    ];
    const attributes = getAllowedAttributes(props, allowedAttributes);
    const buttonRef = useRef(null);
    const menuRef = useRef(null);
    const priceMin = useRef(null);
    const priceMax = useRef(null);
    const [open, setOpen] = useDetectOutsideClick(menuRef, false);
    const [optionsOpen, setOptionsOpen] = useState({});
    const [value, setValue] = useState(props.value.length ? JSON.parse(props.value) : {});
    const dropdownToggle = (event) => {
        event.preventDefault();
        setOpen(!open);
    }
    const getSelectedTitle = () => {
        //const value = getValue();
        let title = 'Any';
        if (Object.entries(value).length > 0) {
            let count = 0;
            Object.entries(value).forEach(([group, filters]) => {
                if (group === 'price') {
                    count += Object.entries(filters).length;
                } else {
                    count += filters.length;
                }
            })
            title = count + ' filter' + (count > 1 ? 's' : '');
        }
        return title;
    }
    const getValue = () => {
        // Remove any groups from the value that are no longer available e.g. when the category is changed and the filter isn't applicable
        Object.entries(value).forEach(([group, filters]) => {
            if (group !== 'price' && !props.filters[group]) {
                delete value[group];
            }
        });
        return value;
    }
    const isSelected = (filterGroup, option) => {
        if (filterGroup === 'price' && typeof value[filterGroup] !== 'undefined') {
            return true;
        }
        return (typeof value[filterGroup] !== 'undefined' && value[filterGroup].includes(option));
    }
    const getMinMax = (type) => {
        //let value = getValue();
        if (typeof value.price !== 'undefined' && typeof value.price[type] !== 'undefined') {
            return value.price[type];
        }
        return '';
    }
    const setMinMax = (event) => {
        event.preventDefault();
        const newValue = getValue();
        newValue.price = {
            min: priceMin.current.value,
            max: priceMax.current.value,
        };
        if (parseFloat(newValue.price.min) >= parseFloat(newValue.price.max)) {
            priceMin.current.value = '';
            delete newValue.price.min;
        }
        if (newValue.price.min === null || newValue.price.min === '') {
            delete newValue.price.min;
        }
        if (newValue.price.max === null || newValue.price.max === '') {
            delete newValue.price.max;
        }
        if (Object.entries(newValue.price).length === 0) {
            delete newValue.price;
        }
        setValue(newValue);
    }
    const toggleOption = (event) => {
        setOptionsOpen({ ...optionsOpen, [event.currentTarget.dataset.value]: !optionsOpen[event.currentTarget.dataset.value]})
    }
    const clearFilter = (event) => {
        setValue({});
        setOpen(false)
    }
    const selectOption = (event, parent = false) => {
        event.preventDefault();
        const targetValue = event.currentTarget.dataset.value;
        const filterGroup = event.currentTarget.dataset.group;
        let filterGroupValues = typeof value[filterGroup] != 'undefined' ? value[filterGroup] : [];
        if (filterGroupValues.includes(targetValue)) {
            filterGroupValues.splice(filterGroupValues.indexOf(targetValue), 1);
        } else {
            filterGroupValues.push(targetValue);
        }
        setValue({ ...value, [filterGroup]: filterGroupValues });
    }
    useEffect(() => {
        const setKeydown = (event) => {
            const dropdownFocused = buttonRef.current && document.activeElement === buttonRef.current;
            if (!dropdownFocused && ['Enter', 'NumpadEnter'].includes(event.code)) {
                event.preventDefault();
            }
            if (open) {
                if (event.code === 'Escape') {
                    setOpen(false);
                }
            }
        }
        document.addEventListener('keydown', setKeydown, false);
        return () => {
            document.removeEventListener('keydown', setKeydown, false);
        };
    }, [open, setOpen, props]);
    useEffect(() => {
        if (Object.keys(props.options).length) {
            const value = getValue();
            setOptionsOpen(Object.keys(props.options)
                .reduce((obj, key) => {
                    obj[key] = typeof value[key] != 'undefined';
                    return obj;
                }, {}));
        }
    // eslint-disable-next-line
    }, [props.options]);
    useEffect(() => {
        if (!open) {
            if (JSON.stringify(getValue()) !== props.value) {
                const changeEvent = { target: { name: props.name, value: JSON.stringify(getValue()) } };
                props.inputChangeHandler(changeEvent);
            }
        }
    }, [open, value]);
    let className = 'form__field-control form__field-control--select' + (open ? ' form__field-control--filter-open' : '');
    return (
        <div className="form__field-filter-wrapper">
            <button ref={ buttonRef } type="button" className={ className } { ...attributes } onClick={ dropdownToggle }>{ getSelectedTitle() }</button>
            <div ref={ menuRef } className={ 'form__field-filter-options' + (open ? ' form__field-filter-options--open' : '') }>
                <div>
                    <ul>
                        {
                            Object.entries(props.options).map(([filterGroup, option]) => {
                                return <li className={ 'form__field-filter-options-list-item' + (optionsOpen[filterGroup] ? ' form__field-filter-options-list-item--selected' : '') } key={ filterGroup }>
                                    <button type="button" data-value={ filterGroup } tabIndex={ open ? '' : '-1' } onClick={ toggleOption }>{ option.title }</button>
                                    <ul className={ 'form__field-filter-options-sub-list' + (optionsOpen[filterGroup] ? ' form__field-filter-options-sub-list--open' : '') }>
                                        { filterGroup === 'price' ?
                                            <li className={ 'form__field-filter-options-list-item form__field-filter-options-list-item--price' + (isSelected(filterGroup, option) ? ' form__field-filter-options-list-item--selected' : '') } key={ filterGroup + '_' + option }>
                                                <div>
                                                    <label>Min.</label>
                                                    <span className="form__field-before">£</span>
                                                    <input className="form__field-control form__field-control--currency" ref={ priceMin } name="priceMin" type="text" defaultValue={ getMinMax('min') }
                                                           onKeyUp={ (event) => setMinMax(event) }
                                                           onBlur={ (event) => setMinMax(event) }
                                                    />
                                                </div>
                                                <div>
                                                    <label>Max.</label>
                                                    <span className="form__field-before">£</span>
                                                    <input className="form__field-control form__field-control--currency" ref={ priceMax } name="priceMax" type="text" defaultValue={ getMinMax('max') }
                                                           onKeyUp={ (event) => setMinMax(event) }
                                                           onBlur={ (event) => setMinMax(event) }
                                                    />
                                                </div>
                                            </li>
                                        : Object.entries(option.options).map(([option, title]) => {
                                            return <li className={ 'form__field-filter-options-list-item' + (isSelected(filterGroup, option) ? ' form__field-filter-options-list-item--selected' : '') } key={ filterGroup + '_' + option }>
                                                <button type="button" data-value={ option } data-group={ filterGroup } tabIndex={ open ? '' : '-1' } onClick={ selectOption }>{ title }</button>
                                            </li>
                                        })
                                        }
                                    </ul>
                                </li>
                            })
                        }
                    </ul>

                    <div className="form__field-filter-options-footer"><Button size="small" onClick={ () => setOpen(false) }>Filter products</Button><Button size="small" outline onClick={ clearFilter }>Clear filters</Button></div>
                </div>
            </div>
        </div>
    );
};

export default ProductFilters;
